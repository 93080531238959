* {
  margin:0;
  padding: 0;
  box-sizing: border-box;
  
}
.App{
  font-family: sans-serif;
  text-align: center;
}

