.footer {
    width: 100%;
    height: 200px;
    background: black;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
  
  }
  
  .media {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .icon {
    color: white;
    font-size: 40px;
    cursor: pointer;
    margin:  0 1rem;
  }
  
  .footer p {
    color: white;
  }
  
  @media only screen and (max-width: 600px) {
    .footer svg {
      font-size: 50px;
    }
  }