form {
    width: 60vw;
    max-width: 500px;
    min-width: 300px;
    margin: 0 auto;
    padding-bottom: 2em;
  }
  
  fieldset {
    border: none;
    padding: 2rem 0;
    border-bottom: 3px solid #3b3b4f;
  }
  
  fieldset:last-of-type {
    border-bottom: none;
  }
  
  label {
    display: block;
    margin: 0.5rem 0;
    color: black;
  }
  
  input,
  textarea {
    margin: 10px 0 0 0;
    width: 100%;
    min-height: 2em;
    border: black;
  
  }
  
  input, textarea {
    background-color: white;
    border: 0.5px solid black;
    color: black;
  }
  
  .inline {
    width: unset;
    margin: 0 0.5em 0 0;
    vertical-align: middle;
  }
  
  input[type="submit"] {
    display: block;
    width: 60%;
    margin: 1em auto;
    height: 2em;
    font-size: 1.1rem;
    background-color:  rgb(14, 210, 14);
    border-color: rgb(14, 210, 14);
    min-width: 300px;
  }
  .Contact{
    background-color: white ;
    padding-bottom: 500PX ;
  
  }
 