.resume-pdf{
    width: 40%; /* Can be in percentage also. */
    height: 100%;
    margin: 0 auto;
    padding: 10px;
    position: relative;
    border: 1px solid black;
    padding-top: 10px;
    align-content: center;
    
    
}

h2{
    text-align: center;
    color: black;
    padding-top: 10px;
    padding-bottom: 20px;
}

.resume{
    background-color: white;
    padding-top: 30px;
    padding-bottom: 30px;
}